/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-lines */
import L from 'leaflet';
import PropTypes from 'prop-types';
import { PureComponent, Suspense } from 'react';
import { Map, TileLayer } from 'react-leaflet';

import ContentWrapper from 'Component/ContentWrapper';
import FIELD_TYPE from 'Component/Field/Field.config';
import { RefType } from 'Type/Common.type';
import { OptionType } from 'Type/Field.type';
import { lowPriorityLazy, setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import { StoreType } from '../../type/StoreFinder.type';

import 'leaflet/dist/leaflet.css';
import '../../style/common.style.scss';
import './StoreFinder.style';

export const StoreCard = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "store-card" */
    '../../component/StoreCard'
));
export const Field = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "field" */
    'Component/Field'
));

/** @namespace Scandiweb/StoreFinder/Route/StoreFinder/Component */
export class StoreFinderComponent extends PureComponent {
    static propTypes = {
        changeStore: PropTypes.func.isRequired,
        prepareMapData: PropTypes.func.isRequired,
        changeCity: PropTypes.func.isRequired,
        handleStoreChange: PropTypes.func.isRequired,
        selectedCity: PropTypes.string.isRequired,
        selectedStore: PropTypes.shape().isRequired,
        citiesOptions: OptionType.isRequired,
        storeOptions: OptionType.isRequired,
        allStores: PropTypes.arrayOf(StoreType).isRequired,
        icon: PropTypes.instanceOf(L.Icon),
        mapRef: RefType.isRequired,
        apiKey: PropTypes.string,
        isOnlyMap: PropTypes.bool
    };

    static defaultProps = {
        icon: null,
        apiKey: null,
        isOnlyMap: false
    };

    renderHeading() {
        return (
            <div block="StoreFinder" elem="Heading">
                <h2 block="StoreFinder" elem="Heading" mods={ { Title: true } }>
                    { __('Our Shops') }
                </h2>
            </div>
        );
    }

    renderCitySelect() {
        const {
            selectedCity,
            citiesOptions,
            changeCity
        } = this.props;

        return (
            <div block="StoreFinder" elem="Select">
                <Suspense fallback={ this.renderLocationSelectFallback() }>
                    <Field
                      type={ FIELD_TYPE.select }
                      mix={ { block: 'StoreLocation', elem: 'Search' } }
                      attr={ {
                          id: 'city-select',
                          name: 'city-select',
                          noPlaceholder: true
                      } }
                      value={ selectedCity }
                      isDisabled={ false }
                      options={ citiesOptions }
                      events={ { onChange: changeCity } }
                    />
                </Suspense>
            </div>
        );
    }

    renderStoreSelect() {
        const {
            selectedStore: { store_name },
            storeOptions,
            handleStoreChange
        } = this.props;

        return (
            <div block="StoreFinder" elem="Select">
                <Suspense fallback={ this.renderLocationSelectFallback() }>
                    <Field
                      type={ FIELD_TYPE.select }
                      mix={ { block: 'StoreLocation', elem: 'Search' } }
                      attr={ {
                          id: 'store-select',
                          name: 'store-select',
                          noPlaceholder: true
                      } }
                      value={ store_name || 'All stores' }
                      isDisabled={ false }
                      options={ storeOptions }
                      events={ { onChange: handleStoreChange } }
                    />
                </Suspense>
            </div>
        );
    }

    renderLocationSelectFallback() {
        return (
            <div block="LocationSelectFallback">
                <div block="LocationSelectFallback" elem="Content" />
            </div>
        );
    }

    renderSelectList() {
        const { mapRef } = this.props;

        return (
            <div block="StoreFinder" elem="SelectList" ref={ mapRef }>
                { this.renderCitySelect() }
                { this.renderStoreSelect() }
            </div>
        );
    }

    renderMarker = (store) => {
        const { changeStore, icon } = this.props;

        const { store_name } = store;

        return (
            <Suspense fallback={ null }>
                <StoreCard
                  key={ `${store_name}marker` }
                  store={ store }
                  changeStore={ changeStore }
                  icon={ icon }
                  isMarker
                />
            </Suspense>
        );
    };

    renderAllMarkers() {
        const { allStores } = this.props;

        return allStores.map(this.renderMarker);
    }

    renderMap() {
        const {
            prepareMapData,
            apiKey
        } = this.props;

        const { centerPosition, bounds, padding } = prepareMapData();
        const url = apiKey ? `https://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=${apiKey}` : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        const defaultCenterPosition = [0, 0];

        return (
            <div block="StoreFinder" elem="Map">
                <Map
                  center={ centerPosition || defaultCenterPosition }
                  zoom={ 13 }
                  bounds={ bounds }
                  boundsOptions={ { padding } }
                  duration={ 0.8 }
                  animate
                  useFlyTo
                  whenReady={ setLoadedFlag }
                >
                    <TileLayer
                      url={ url }
                      updateWhenIdle={ false }
                      reuseTiles
                    />
                    { this.renderAllMarkers() }
                </Map>
            </div>
        );
    }

    renderStoreCard = (store) => {
        const { store_name } = store;

        const {
            selectedStore: { store_name: selectedStoreName },
            changeStore,
            mapRef
        } = this.props;

        return (
            <Suspense fallback={ <div block="StoreCardsFallback" /> }>
                <StoreCard
                  key={ store_name }
                  store={ store }
                  mapRef={ mapRef }
                  isActive={ store_name === selectedStoreName }
                  changeStore={ changeStore }
                />
            </Suspense>
        );
    };

    renderStoreCards() {
        const { allStores } = this.props;

        if (!allStores.length) {
            return (
                <div block="StoreCardsFallback" />
            );
        }

        return (
            <div block="StoreFinder" elem="StoreCards">
                { allStores.map((store) => this.renderStoreCard(store)) }
            </div>
        );
    }

    renderMainContent() {
        return (
            <div block="StoreFinder" elem="MainContent">
                { this.renderHeading() }
                { this.renderSelectList() }
                { this.renderMap() }
                { this.renderStoreCards() }
            </div>
        );
    }

    render() {
        const { isOnlyMap } = this.props;

        if (isOnlyMap) {
            return (
                <div block="StoreFinder">
                    { this.renderMap() }
                </div>
            );
        }

        return (
            <main block="StoreFinder">
                <ContentWrapper
                  wrapperMix={ { block: 'StoreFinder', elem: 'ContentWrapper' } }
                  label={ __('Our Shops') }
                >
                    { this.renderMainContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default StoreFinderComponent;
