/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-lines */
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { HistoryType, LocationType } from 'Type/Router.type';
import { scrollToTop } from 'Util/Browser/Browser';
import { withReducers } from 'Util/DynamicReducer';

import {
    COLUMNS_AT_DESKTOP,
    COLUMNS_AT_TABLET,
    MAP_PADDING,
    MARKER_ICON_CLASS_NAME,
    MARKER_ICON_HEIGHT,
    MARKER_ICON_WIDTH,
    MAX_TABLET_WIDTH,
    META_OBJECT_META_KEYWORD,
    MIN_HEIGHT_STORECARD_TITLE,
    POSITION,
    STORE_FINDER_HEADER_TAG,
    STORE_FINDER_PAGE_URL,
    WORKING_HOURS_POS
} from '../../config/StoreFinder.config';
import { StoreFinderReducer } from '../../store/StoreFinder/StoreFinder.reducer';
import {
    LocationsType,
    LocationType as StoreLocationType
} from '../../type/StoreFinder.type';
import { handleOnBackClick, sortBySortOrder } from '../../util/Helper';
import StoreFinder from './StoreFinder.component';

export const StoreFinderDispatcher = import(
    /* webpackMode: "eager"*/
    '../../store/StoreFinder/StoreFinder.dispatcher'
);

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "eager"*/
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Scandiweb/StoreFinder/Route/StoreFinder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    storeListCities: state.StoreFinderReducer.storeListCities,
    storeListMapped: state.StoreFinderReducer.storeListMapped,
    storeByName: state.StoreFinderReducer.storeByName,
    isMobile: state.ConfigReducer.device.isMobile,
    isLoading: state.StoreFinderReducer.isLoading,
    apiKey: state.StoreFinderReducer.apiKey
});

/** @namespace Scandiweb/StoreFinder/Route/StoreFinder/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestStores: () => StoreFinderDispatcher.then(({ default: dispatcher }) => dispatcher.requestStoreData(dispatch)),
    updateBreadcrumbs: () => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update([{ url: STORE_FINDER_PAGE_URL, name: __('Shops') }], dispatch)
    ),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    requestAPIKey: () => StoreFinderDispatcher.then(({ default: dispatcher }) => dispatcher.requestAPIKey(dispatch)),
    updateMeta: (meta) => dispatch(updateMeta(meta))
});

/** @namespace Scandiweb/StoreFinder/Route/StoreFinder/Container */
export class StoreFinderContainer extends PureComponent {
    static propTypes = {
        location: LocationType.isRequired,
        history: HistoryType.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        requestStores: PropTypes.func.isRequired,
        storeListCities: PropTypes.arrayOf(PropTypes.string),
        storeListMapped: PropTypes.objectOf(LocationsType),
        storeByName: StoreLocationType,
        setHeaderState: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        requestAPIKey: PropTypes.func.isRequired,
        apiKey: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isOnlyMap: PropTypes.bool,
        updateMeta: PropTypes.func.isRequired
    };

    static defaultProps = {
        storeListCities: null,
        storeListMapped: null,
        storeByName: null,
        isOnlyMap: false
    };

    containerFunctions = {
        changeCity: this.changeCity.bind(this),
        changeStore: this.changeStore.bind(this),
        handleStoreChange: this.handleStoreChange.bind(this),
        prepareStorePosition: this.prepareStorePosition.bind(this),
        prepareStoreData: this.prepareStoreData.bind(this),
        prepareMapData: this.prepareMapData.bind(this),
        prepareStoreOptions: this.prepareStoreOptions.bind(this),
        prepareCityOptions: this.prepareCityOptions.bind(this)
    };

    state = {
        allStores: [],
        citiesOptions: [],
        storeOptions: [],
        selectedCity: __('All Cities'),
        selectedStore: {},
        metaObject: {},
        icon: null,
        alignedStoreCards: false
    };

    mapRef = createRef();

    handleResize = this.handleResize.bind(this);

    componentDidMount() {
        const {
            requestStores,
            updateBreadcrumbs,
            requestAPIKey,
            location: { pathname } = {},
            apiKey
        } = this.props;

        window.addEventListener('resize', this.handleResize, { passive: true });

        history.scrollRestoration = 'manual';

        scrollToTop();

        const isStoreFinderPage = pathname?.includes(STORE_FINDER_PAGE_URL);

        // If the map is in another page for example contact us page we will get wrong meta and breadcrumbs
        if (isStoreFinderPage) {
            updateBreadcrumbs();
            this.updateMeta();
        }

        requestStores();
        this.setHeaderState();

        const icon = new L.Icon({
            iconRetinaUrl,
            iconUrl,
            shadowUrl,
            className: MARKER_ICON_CLASS_NAME,
            iconSize: new L.Point(MARKER_ICON_WIDTH, MARKER_ICON_HEIGHT)
        });

        this.setState({ icon });

        if (!apiKey) {
            requestAPIKey();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            storeListMapped,
            storeListCities,
            requestStores,
            updateBreadcrumbs,
            location: { pathname } = {}
        } = this.props;

        const {
            storeListCities: prevStoreListCities,
            storeListMapped: prevStoreListMapped,
            location: { pathname: prevPathname } = {}
        } = prevProps;

        const {
            selectedCity,
            allStores,
            alignedStoreCards
        } = this.state;

        const {
            selectedCity: prevSelectedCity,
            allStores: prevAllStores
        } = prevState;

        if (pathname !== prevPathname) {
            this.prepareMetaObject();
            requestStores();
            updateBreadcrumbs();
            this.setHeaderState();
            this.updateMeta();
        }

        if (
            storeListMapped !== prevStoreListMapped
            || selectedCity !== prevSelectedCity
        ) {
            this.setState({ allStores: this.prepareStoreData() });
        }

        if (storeListCities !== prevStoreListCities) {
            this.setState({ citiesOptions: this.prepareCityOptions() });
        }

        if (allStores !== prevAllStores) {
            this.setState({ storeOptions: this.prepareStoreOptions() });
        }

        if (!alignedStoreCards) {
            this.alignStoreCards();
        }
    }

    componentWillUnmount() {
        history.scrollRestoration = 'auto';
        window.removeEventListener('resize', this.handleResize);
    }

    containerProps() {
        const {
            isLoading,
            location,
            requestStores,
            storeListCities,
            storeListMapped,
            storeByName,
            setHeaderState,
            isOnlyMap,
            apiKey
        } = this.props;

        const {
            allStores,
            citiesOptions,
            storeOptions,
            selectedCity,
            selectedStore,
            metaObject,
            icon
        } = this.state;

        return {
            isLoading,
            location,
            requestStores,
            storeListCities,
            storeListMapped,
            storeByName,
            setHeaderState,
            allStores,
            citiesOptions,
            storeOptions,
            selectedCity,
            selectedStore,
            metaObject,
            icon,
            mapRef: this.mapRef,
            apiKey,
            isOnlyMap
        };
    }

    handleResize() {
        this.setState({ alignedStoreCards: false });
    }

    getWorkHoursHeight(child) {
        // vvv Get Working hours & Get Directions button height
        const subChld = child.children[1].children;

        // eslint-disable-next-line no-return-assign, no-param-reassign
        return Array.from(subChld).reduce((acc, chld) => acc += chld.offsetHeight, 0);
    }

    alignStoreCards() {
        const { isMobile } = this.props;
        const storeCards = document.querySelector('.StoreFinder-StoreCards')?.children || [];

        if (!storeCards.length || isMobile) {
            return;
        }

        const maxHeights = {};
        const obj = {};
        const workingHoursPos = WORKING_HOURS_POS;
        const columnsByPage = window.innerWidth <= MAX_TABLET_WIDTH ? COLUMNS_AT_TABLET : COLUMNS_AT_DESKTOP;

        // determine max heights for each of the elements in store card between all of the instances of this element in other cards
        /* eslint-disable fp/no-let */
        for (let i = 0; i < storeCards.length; i++) {
            const { children } = storeCards[i];
            const rest = i % columnsByPage;
            const idx = Math.floor(i / columnsByPage);

            // Calculate height of each of group cards (amount of cards in group determine the columnsByPage)
            if (rest === 0) {
                // eslint-disable-next-line fp/no-delete
                Object.keys(obj).forEach((key) => delete obj[key]);

                // vvv Set the height of the Title block
                obj[0] = MIN_HEIGHT_STORECARD_TITLE;
            }

            for (let j = 0; j < children.length; j++) {
                const child = children[j];

                // skip image
                if (j !== 1 && child.children.length) {
                    // Calculate the Working Hours block's height depend on its element's height. Not from incoming!
                    const maxHeightInRow = j === workingHoursPos
                        ? this.getWorkHoursHeight(child)
                        : child.offsetHeight;

                    if (!obj[j] || maxHeightInRow > obj[j]) {
                        obj[j] = maxHeightInRow;
                    }
                }
            }

            maxHeights[idx] = { ...maxHeights[idx], ...obj };
        }

        // assign height based on max height to each element to align them
        for (let i = 0; i < storeCards.length; i++) {
            const { children } = storeCards[i];
            const idx = Math.floor(i / columnsByPage);

            for (let j = 0; j < children.length; j++) {
                // skip image
                if (j !== 1) {
                    const child = children[j];

                    child.style.height = maxHeights[idx][j] ? `${maxHeights[idx][j]}px` : 0;
                }
            }
        }
        /* eslint-enable fp/no-let */

        this.setState({ alignedStoreCards: true });
    }

    changeCity(selectedCity) {
        if (selectedCity.target) {
            this.setState({
                selectedCity: selectedCity.target.value,
                selectedStore: {}
            });
        } else {
            this.setState({ selectedCity, selectedStore: {} });
        }
    }

    changeStore(selectedStore, scrollIntoMap = false) {
        this.setState({ selectedStore });

        if (!scrollIntoMap) {
            return;
        }

        this.mapRef.current?.scrollIntoView({
            behavior: 'smooth',
            top: 0
        });
    }

    handleStoreChange(selectedStoreName) {
        const { storeByName } = this.props;
        const { target } = selectedStoreName;

        if (selectedStoreName.target) {
            const { value } = target;

            this.changeStore(storeByName[value] || {});
        } else {
            this.changeStore(storeByName[selectedStoreName] || {});
        }
    }

    prepareMapBounds(allStores) {
        const latLongArr = allStores.map(this.prepareStorePosition);

        return L.latLngBounds(latLongArr);
    }

    prepareStorePosition(
        { latitude, longitude } = { latitude: '', longitude: '' }
    ) {
        if (!latitude || !longitude) {
            return null;
        }

        return [latitude, longitude];
    }

    prepareStoreData() {
        const { storeListMapped } = this.props;
        const { selectedCity } = this.state;

        const cityStores = storeListMapped[selectedCity]
            || Object.values(storeListMapped).reduce((stores, cityStores) => {
                // eslint-disable-next-line no-param-reassign
                stores = [...stores, ...cityStores];
                return stores;
            }, []);

        return cityStores.reduce((validStores, allStore) => {
            const {
                latitude,
                longitude,
                city,
                store_name
            } = allStore;

            if (latitude !== 0 && longitude !== 0 && city) {
                const sortInsertIndex = validStores.findIndex(
                    ({ city: validCity, store_name: validStoreName }) => {
                        if (city === validCity) {
                            return validStoreName > store_name;
                        }

                        return validCity > city;
                    }
                );

                const insertPosition = sortInsertIndex || validStores.length;

                validStores.splice(insertPosition, 0, {
                    ...allStore,
                    active: false
                });
            }

            validStores.map((store) => {
                const { position } = store;
                const storePosition = Number(position);
                const numPosition = typeof storePosition === 'number' ? storePosition : 0;

                return { ...store, position: numPosition };
            });

            return sortBySortOrder(validStores, POSITION);
        }, []);
    }

    prepareCityOptions() {
        const { storeListCities: cities } = this.props;

        const validCityOptions = cities.reduce((options, city) => {
            if (city) {
                const sortInsertIndex = options.findIndex(
                    ({ label }) => label > city
                );
                const insertPosition = sortInsertIndex || options.length;

                options.splice(insertPosition, 0, {
                    id: city.replace(/\s/g, ''),
                    value: city,
                    label: city
                });
            }

            return options;
        }, []);

        const allCitiesLabel = __('All Cities');

        validCityOptions.unshift({
            id: allCitiesLabel,
            value: allCitiesLabel,
            label: allCitiesLabel
        });

        return validCityOptions;
    }

    prepareStoreOptions() {
        const { allStores } = this.state;

        return (
            [{ store_name: __('All Stores') }, ...allStores].map(
                ({ store_name }) => ({
                    id: store_name,
                    value: store_name,
                    label: store_name
                })
            ) || []
        );
    }

    prepareMapData() {
        const {
            selectedStore,
            selectedStore: { store_name }
        } = this.state;
        const allStores = this.prepareStoreData();
        const hasMultipleStores = allStores.length > 1;

        return {
            allStores,
            centerPosition: this.prepareStorePosition(
                hasMultipleStores ? selectedStore : allStores[0]
            ),
            bounds:
                hasMultipleStores && !store_name
                    ? this.prepareMapBounds(allStores)
                    : null,
            padding: [MAP_PADDING, MAP_PADDING]
        };
    }

    prepareMetaObject() {
        const shopsLabel = __('Our Shops');

        const metaObject = {
            name: shopsLabel,
            title: shopsLabel,
            meta_title: shopsLabel,
            meta_description: __('Our Shops - Find the closest store'),
            meta_keyword: META_OBJECT_META_KEYWORD
        };

        this.setState({ metaObject });
    }

    updateMeta() {
        const { updateMeta } = this.props;

        updateMeta({ title: __('Our Shops') });
    }

    setHeaderState() {
        const { setHeaderState } = this.props;

        setHeaderState({
            name: STORE_FINDER_HEADER_TAG,
            title: __('Our Stores'),
            onBackClick: handleOnBackClick
        });
    }

    render() {
        return (
            <StoreFinder
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withReducers({
    StoreFinderReducer
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreFinderContainer));
